import React from "react"
import { Helmet } from "react-helmet";

export default function Contact(props) {
    const { title, url, meta_description, date_published, date_modified } = props

    const schema = {
        "@context": "http://schema.org",
        "@type": "ContactPage",
        "url": url,
        "name": title,
        "description": meta_description,
        "inLanguage": "en-US",
        "datePublished": date_published,
        "dateModified": date_modified
    }

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    )
}
